@import "../abstracts/abstracts";

.svg-hover {
  &:hover {
    background-color: $white;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-color;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-color;
}
