@import "../scss/abstracts/abstracts";

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.product_desp_html {
  ul,
  ol,
  li {
    padding-left: 20px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

html,
body {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
  font-family: "Proxima Nova" !important;
  width: 100%;
  height: 100%;
  // font-family: Avenir, sans-serif;
  // font-family: Roboto, sans-serif;
  // background-color: #E5E5E5;
}

@media only screen and (max-width: 600px) {
  body,
  html {
    position: relative;
    overflow-x: hidden !important;
    /* Hide horizontal scrollbar */
  }

  .productImageBox {
    width: 100% !important;
    height: 200px !important;
  }
}

.hand {
  cursor: pointer;
}

.staticContentPg {
  width: 90%;
  margin: auto;

  .link {
    font-size: 1rem;
    color: #222;
    text-decoration: underline;
  }
}

.font-s-14 {
  font-size: 0.875rem !important;
}

.listsData {
  list-style: none !important;
}

#Orders {
  .nav.nav-tabs {
    border: none;

    .nav-item {
      border: inherit;
    }

    .nav-item.nav-link {
      color: $primary-color;
    }

    a.active {
      font-weight: 600;
      background: linear-gradient(91.1deg, #cda774 -8.1%, #ab7c62 124.87%);
      color: white !important;
      border-radius: 0.5rem !important;
    }
  }

  .ordersCard {
    border-radius: 8px;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.12);

    img {
      height: 100px;
    }

    .position-relative {
      top: auto;
      bottom: 33%;
      left: 55%;
      right: auto;
    }

    p span {
      color: $grey-9b;
    }

    .status {
      p {
        color: $green-2a;
      }
    }
  }
}

#MyOrder {
  .returnBtn {
    background: linear-gradient(91.1deg, #cda774 -8.1%, #ab7c62 124.87%);
    box-shadow: 2px 8px 15px rgba(20, 20, 20, 0.17);
    padding-block: 0.3rem;
    // border-radius: 8px !important;
    border: inherit;
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
  #tracking {
    p {
      font-weight: 600;

      span {
        font-weight: 400;
        color: $grey-9b;
      }
    }
  }

  .bg-grey-ed {
    background-color: #f6f4e5 !important;
  }

  #returnTimeline {
    .return_timeline_heading {
      font-size: 20px;
      font-weight: 600;
    }
    .return_timeline {
      .timeline-day {
        font-size: 14px;
        font-weight: 400;
      }
      .timeline-info-message {
        background-color: #fff4e7;
        border: 0.5px solid #caa474;
        border-radius: 4px;
        font-weight: 500;
        padding: 0.2rem 0.3rem;
        width: fit-content;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
      }
      .timeline-item {
        position: relative;
        padding-bottom: 2.5rem;
      }
      .timeline-item::before {
        content: "";
        width: 50px;
        /* background: whitesmoke; */
        height: 108%;
        position: absolute;
        left: -3.1rem;
        bottom: -37%;
        border-top: 0.5px solid #caa474;
        border-left: 0.5px solid #caa474;
        border-top-left-radius: 7px;
      }
      .timeline-item:nth-last-child(2)::before {
        height: 84%;
        bottom: -13%;
      }
      .timeline-item:last-child:before {
        border: none;
        background: url("../svg/account_circle.svg") no-repeat center center;
        // background-size: 10%;
        width: 25px;
        height: 25px;
        left: -62px;
        top: 18%;
      }
      .custom-container {
        padding-inline-start: 3.5rem;
        font-size: 14px;
        .timeline-item_bottom span {
          margin-inline-end: 4px;
          font-size: 12px;
        }
        .timeline-item_date {
          font-size: 12px;
        }

        .timeline-orange-flag {
          position: absolute;
          left: -60px;
          bottom: 0.5rem;

          background-color: #f5d2a5;
          border-radius: 2px;
          padding: 0rem 0.3rem;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          -ms-border-radius: 2px;
          -o-border-radius: 2px;
        }

        .timeline-grey-flag {
          background: #e2e8f0;
          border-radius: 4px;
          border: none;
          padding: 0.1rem 0.2rem;
          -webkit-border-radius: 2px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
        }
      }
      .return_tracking_img-container {
        border: 1px solid #9b9b9b;
        border-radius: 10px;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;

        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
      .return_tracking_img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

#ReturnForm {
  .submitBtn {
    background: linear-gradient(91.1deg, #cda774 -8.1%, #ab7c62 124.87%);
    box-shadow: 2px 8px 15px rgba(20, 20, 20, 0.17);
    // border-radius: 8px !important;
    border: inherit;
    color: white;
    font-size: 18px;
    font-weight: 500;
  }
  input[type="file"] {
    padding-block: 1rem;
    height: auto;
  }
  .formFlex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 2rem;
    .flex-child {
      flex: 1;
      input,
      select {
        height: auto;
        padding-block: 1rem;
      }
    }
  }
}

#Blogs {
  .card {
    border: 1px solid #d5d5d5;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.09);
    border-radius: 6px;

    h2 {
      font-size: 18px;
      font-weight: 600;
    }

    .card-header {
      background-color: $grey-ee;
      min-height: 200px;
    }

    p.font-s-14 {
      font-size: 0.875rem !important;
    }

    .btn.btn-outline-dark {
      color: #ada174;
      border: 1px solid #d5d5d5;
      box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.09);
      border-radius: 6px;
    }
  }

  .page-link-blog {
    position: relative;
    background-color: $white-f8;
    display: block;
    padding: 0.5rem 0.75rem;
    margin: 0 15px;
    line-height: 1.25;
    color: $black;
    // background: linear-gradient(91.1deg, #CDA774 -8.1%, #AB7C62 124.87%);
    box-shadow: 2px 8px 15px rgba(20, 20, 20, 0.17);
    border-radius: 30px;
    text-decoration: none;
    // border: 1px solid #dee2e6;
  }

  .active-blog {
    .page-link-blog {
      background: linear-gradient(91.1deg, #cda774 -8.1%, #ab7c62 124.87%);
      box-shadow: 2px 8px 15px rgba(20, 20, 20, 0.17);
      color: $white-f8;
    }
  }

  .previous,
  .next {
    font-size: 1.125rem;
    margin: 5px 20px;
  }
}

#BlogDetail {
  .title {
    background-color: $grey-ee;
  }

  #customerService {
    h1 {
      font-family: Perpetua;
    }

    .nav {
      background: rgba(67, 67, 67, 0.09);
      border-radius: 6px 0px 0px 6px;

      .nav-item {
        .nav-link {
          font-weight: 900;
          color: $green-a6;

          &.active {
            color: white !important;
            background: linear-gradient(
              91.1deg,
              #cda774 -8.1%,
              #ab7c62 124.87%
            );
          }
        }
      }
    }
  }
}

@media (min-width: var(--breakpoint-sm)) {
  .border-sm-none {
    border: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .productImageBox img {
    width: 50% !important;
    padding: 10px !important;
  }

  .staticContentPg {
    width: 100%;
  }

  .mob-grad-bg {
    background-color: #eeeae6 !important;
  }

  .size-14 {
    font-size: 0.875rem;
  }

  #Orders {
    .w-90 {
      width: 100% !important;
    }

    .nav.nav-tabs {
      .nav-item.nav-link {
        font-size: 0.875rem;
      }

      a.active {
        font-weight: 600;
        background: linear-gradient(91.1deg, #cda774 -8.1%, #ab7c62 124.87%);
        color: white !important;
        border-radius: 0.5rem !important;
      }
    }

    .ordersCard {
      border-radius: 8px;
      box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.12);

      p {
        font-size: 14px;
      }

      img {
        height: 69px;
      }

      .position-relative {
        bottom: 24%;
        left: 55%;
      }
    }
  }

  #MyOrder {
    .tracking {
      p {
        .d-sm-inline {
          font-size: 0.875rem !important;
        }
      }
    }

    .card-custom {
      background: #ffffff;
      box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      margin-bottom: 0.9rem;
      padding-top: 0.8rem !important;

      .w-100 {
        .border-bottom {
          border-color: $yellow-c9 !important;
        }
      }

      .size-14 {
        .text-grey-9b {
          font-weight: normal;
        }

        font-weight: 900;
      }
    }
  }

  #Blogs {
    .card {
      h2 {
        font-size: 14px;
      }

      h4 {
        font-size: 0.85rem;
      }

      div p {
        font-size: 0.675rem;
      }

      p.font-s-14 {
        font-size: 0.675rem !important;
      }
    }
  }

  #customerService {
    h1 {
      font-size: 2rem;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.colorContainer {
  display: flex;
  align-items: center;
  .colorParent {
    margin-right: 1rem;
    p {
      font-size: 0.85rem;
    }
  }
  .colorParent:nth-child(4) {
    margin-right: 0.85rem;
  }
}

.colorListDot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  border: 0.5px solid;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blogsContent {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  p {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px;
    color: #070707;
  }
}

.blogCardImg {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 02px;
}

.breadCrumbLinkState {
  color: #212529;
  text-underline-offset: 3px;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

.activeLinkState {
  color: #999999;
}

.blogsSheet {
  color: #895133;
  text-underline-offset: 3px;

  &:hover {
    color: #895133;
    text-decoration: underline;
  }
}

.blogCartTitle {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #895133;
}

.authorName {
  font-size: 14px;
}

.blogCardContent {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.smallFont {
  font-size: 14px;
  color: #656565;
}

.blogDetailMainPage {
  h2 {
    font-size: 20px;
    font-weight: 600;
  }
  .breadCrumbMain {
    font-size: 12px;
  }
  .detailsPageTitle {
    margin-block: 0.2rem 1.5rem;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0px;
    }

    small {
      color: #999999;
      font-size: 12px;
      font-weight: 400;
      text-transform: capitalize;
      font-style: normal;
    }
  }

  .custRounded {
    border-radius: 8px;
    overflow: hidden;
    overflow: clip;
    margin-block-end: 1rem;
  }
}
.paginationBtn {
  background: none;
  border: none;
  outline: none;
}

.product-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1680px;
}

.css-g1d714-ValueContainer,
.css-1hwfws3 {
  padding-left: 30px !important;
}

.searchBar {
  position: relative;

  .searchIcon {
    position: absolute;
    z-index: 1;
    top: 10%;
    left: 2%;
  }
}

#dropdown-basic {
  .dropdown {
    display: flex !important;
    align-items: center !important;
  }

  width: 100%;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: transparent;
  font-size: 0.85rem;
  line-height: 21px;
  font-family: "Poppins";
  color: black;
  padding: 0px !important;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:after {
    display: none;
  }
}

.productImageBox {
  width: 100%;
  height: 400px;
  border: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 20px;
  }
}

.logoutBtn {
  width: 100%;
  border: 1px solid #ab7c62;
  background: transparent;
  color: #ab7c62;
  padding: 10px;
}

.sortByDrop {
  outline: none;
  border: none;
  background: transparent;
}

.categorySwiper {
  position: relative;
  z-index: 0;
}

.catePrevBtn {
  position: absolute;
  top: 106%;
  left: 2%;
  z-index: 2;

  img {
    width: 30%;
  }
}

.cateNextBtn {
  position: absolute;
  top: 106%;
  right: -14%;
  z-index: 2;

  img {
    width: 30%;
  }
}

.new-badge {
  background-color: #b78b68;
  color: #fff;
  padding: 5px 7px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 0px;
}

.product-page-bg {
  background-color: #f5f5f5;
}

.navbar-box-shadow {
  background-color: #f5f5f5;
  box-shadow: 0 2px 8px #818181c7 !important;
}

.product--container {
  background-color: #f5f5f5;
  margin-bottom: 0.8rem;
  padding: 0 0.55rem;

  .price {
    background-color: #fff;
    padding: 0.4rem 0.6rem 0.2rem 0.4rem;
    font-size: 0.9rem;
    position: relative;
  }
}

.product-title {
  font-size: 1.1rem;
  // padding: 0 0.55rem;
}

.product-type {
  font-size: 0.9rem;
  padding: 0 0.55rem;
  color: #929292;
}

.collectionTabs {
  margin-bottom: 1.8rem;
}

#detail_1 {
  margin-bottom: 1.2rem !important;
}

.product--a,
.product--a:hover {
  text-decoration: none;
  color: unset;
  cursor: pointer;
}

#multipleTabs {
  margin-bottom: 2.8rem !important;
}

#imgBlocks {
  margin-bottom: 1.6rem !important;
}

.main--title {
  &.varies {
    font-size: 1.6rem;
  }
  font-family: "Perpetua";
  font-size: 1.9rem;
  letter-spacing: 0rem;
}

.search-container {
  position: relative;

  .search--div {
    position: absolute;
  }
}

.product--container .heree.img-fluid {
  display: none;
}

.product--container .img-fluid.img0 {
  display: block;
}

@media only screen and (max-width: 600px) {
  .signIn-container {
    display: none;
  }

  .product-title {
    font-size: 1rem;
  }

  #home #multipleTabs .nav.nav-tabs .nav-item.nav-link {
    font-size: 1rem !important;
  }

  #multipleTabs {
    padding: 1.5rem 0 !important;
    margin-bottom: 0 !important;

    h4 {
      padding: 20px 0 !important;
      margin-bottom: 0;
    }
  }

  .cateNextBtn {
    position: absolute;
    top: 100%;
    right: -14%;
    z-index: 2;
  }

  .catePrevBtn {
    position: absolute;
    top: 100%;
    left: 0%;
    z-index: 2;
  }

  .d-none-mob {
    display: none;
  }

  .footer-pdng {
    padding-bottom: 0 !important;
  }
}

.primary-header {
  border-top: 1px solid #eeeae6;
  padding-top: 0;
  padding-bottom: 0;

  .nav-link {
    color: #000;
    font-size: 1.023em;
  }

  .nav-item {
    margin-right: 1em;
  }

  .nav-item:last-child .nav-link {
    margin-right: 0em;
  }

  .nav-link.active {
    border-bottom: 3px solid #c39b6f;
    padding-bottom: 0.3rem;
  }

  .nav-link:hover {
    border-bottom: 3px solid #c39b6f;
    padding-bottom: 0.3rem;
  }
}

.product--card {
  margin-bottom: 2.3em;
}

.menu {
  width: 22px;
  height: 16px;
  cursor: pointer;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 20px;
  transition: 0.5s ease;
}

.menu span {
  width: 100%;
  height: 2px;
  border-radius: 99px;
  background: #222;
  transition: 0.5s ease;
  transform-origin: left;
}

.menu.active span {
  background: #222;
}

.menu.active span:nth-child(1) {
  transform: rotate(40deg);
}

.menu span:nth-child(3) {
  transform-origin: left;
}

.menu.active span:nth-child(3) {
  transform: rotate(-40deg);
}

.menu.active span:nth-child(2) {
  transform: scale(0);
}

.collection--header {
  background-color: #f3eddf;
  height: 43vh;
  display: flex;
  align-items: center;
  margin-bottom: 4em;

  .breadcrumbb {
    display: flex;
    align-items: center;
    padding-left: 0;
    list-style-type: none;

    li {
      margin-right: 1em;

      a {
        color: initial;
      }
    }
  }

  h1 {
    font-family: "Perpetua";
    font-size: 2.7rem;
  }
}

.sortByDrop {
  cursor: pointer;
}

.cate {
  margin-bottom: 1.3em;
}

@media (max-width: 910px) {
  .menu {
    display: flex;
  }
}

@media only screen and (min-width: 1400px) {
  .big-container .container {
    max-width: calc(100% - 8.5vw);
  }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  margin-top: 0;
}

.category--template {
  margin-bottom: 5rem;

  .category--cards {
    margin-bottom: 2rem;
  }
}

.primary-header {
  border-bottom: 1px solid #eeeae6;
}

.sear {
  cursor: pointer;
  opacity: 0.5;

  svg {
    width: 24px;
    height: 24px;
  }

  .divv {
    display: flex;
    align-items: center;

    p {
      margin: 0;
    }
  }
}

.secondary-header {
  padding-top: 1.2rem;
  padding-bottom: 0.9rem;

  .navbar-collapse {
    justify-content: center;
  }
}

.line-through {
  margin-left: 0.8rem;
}

@media (orientation: landscape) {
  #Product .dropdown .nav-link {
    display: block !important;
  }
}

@media (orientation: portrait) {
  .category--cards {
    margin-bottom: 1rem;
  }

  #category #topNav .bg-gradient {
    font-size: 0.85rem !important;
    padding-top: 0.6rem !important;
    padding-bottom: 0.4rem !important;
  }

  .collection--header {
    height: 35vh;
    margin-bottom: 2.8em;
    padding-top: 1.7em;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 0.4rem;
      line-height: 1;
    }
  }
}

.pb-8px {
  padding-bottom: 8px;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  content-visibility: auto;
  contain-intrinsic-size: 100%;
}

.dropdown-item {
  border-bottom: 1px solid #eeeae6;
  white-space: normal !important;
}



.dropdown-menu {
  // width: 240px !important;
  padding: 0.5rem 0 0 0 !important;
  white-space: normal !important;
}

.addOverflowHidden {
  overflow: hidden;
}

.social-icons {
  position: relative;
  display: inline-block;

  img {
    width: 40px;
    height: 40px;
    transition: 0.5s ease;
  }
}

.fbIconContainer .fbFillIcon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.fbIconContainer:hover .fbFillIcon {
  display: inline;
  background-color: #eeeae6;
  // border-radius: 90%;
}

.instaIconContainer .instaFillIcon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.instaIconContainer:hover .instaFillIcon {
  display: inline;
  background-color: #eeeae6;
}

.youTubeIconContainer .youTubeFillIcon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.youTubeIconContainer:hover .youTubeFillIcon {
  display: inline;
  background-color: #eeeae6;
}

.ico:hover img {
  transform: scale(1.5);
  transition: 0.5s ease;
  cursor: pointer;
}

.currency-container {
  position: fixed;
  bottom: 15%;
  left: 1%;
  padding: 10px;
}

#splide01 {
  border: 1px solid #f0f0f0;
  height: inherit;
  // background-color: #fafafa;
  text-align: center;
}

#splide02 {
  background-color: transparent;
  margin-top: 0.8rem;

  .splide__track--nav > .splide__list > .splide__slide {
    border: 1px solid #f0f0f0;
    // background-color: #fafafa;
  }
}

#splide01,
#splide02 .splide__track--nav > .splide__list > .splide__slide {
  background-color: #fafafa;
}

.mob-mt {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

// .product-bottom-carousel {
//   background-color: #f5f5f5;
// }

.product-bottom-carousel-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 13px;
  border: 1px solid #d3d3d3;
  margin: 6px;
  padding: 4px;
}

.splide__arrow {
  background: unset !important;
  opacity: 0.2 !important;
}

.splide__arrow svg {
  fill: gray !important;
}

.prod-mob-splide-block {
  display: none;
}

.product-title {
  font-size: 14px;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 1px solid #bf946c !important;
}

.product-main-image .splide__slide {
  height: 400px !important;
  background-size: contain !important;
}

.product-main-image {
  background-color: #f5f5f5;
}

.slideer button:disabled {
  display: none;
}

@media only screen and (max-width: 600px) {
  .category--template {
    margin-bottom: 0;
  }

  #splide02 {
    margin: 0;
    padding: 0;
    margin-top: 1.2rem;
  }

  .mob-mt {
    margin-top: unset;
    margin-bottom: 2rem;
  }

  .desk-prod-splide-none {
    display: none;
  }

  .prod-mob-splide-block {
    display: block;
  }
}

.quantity-col {
  justify-content: unset !important;
}

.select-size-col {
  margin-right: 1rem !important;
}

.mob-block {
  display: block;
}

.product--container {
  padding-top: 0.8rem;
}

.product--container:hover .img0 {
  display: none;
}

.product--container:hover .img1 {
  display: block;
  transform: scale(1.1);
}

.product--container .img1 {
  transition: all 0.5s ease;
}

.menu-arrow {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  transition: all 0.5s ease;
}
.mobileAnchor {
  padding: 0.25rem 1.5rem;
  width: 100%;
  height: 100%;
  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #212529;
    &:hover,
    &:focus {
      background-color: #e9ecef;
    }
  }
}
.rotate-180 {
  transform: rotate(90deg);
}

.product-thumbnail .splide__arrow--prev {
  display: none;
}

.product-thumbnail .splide__arrow--next {
  display: none;
}

#Product .text-minus .quantityBox {
  padding: 2px 12px;
}

.primary-ul {
  padding-left: 1rem;
  margin-top: 1.4rem;
  li {
    margin-bottom: 0.5rem;
  }
  ul {
    padding-left: 1rem;
    li {
      margin-bottom: 0.3rem;
    }
  }
}

.free-text {
  color: #656565 !important;
  padding-left: 1rem;
  font-size: 1rem;
}

.nav-item.nav-link.active:not(#Orders .nav-item.nav-link.active) {
  padding: 4px 0 !important;
}

.css-14hdpt-control:hover {
  box-shadow: 0 0 0 1px #b68a68 !important;
  border-color: #b68a68 !important;
}

.css-14hdpt-control {
  box-shadow: 0 0 0 1px #b68a68 !important;
  border-color: #b68a68 !important;
}

.searchBar {
  cursor: pointer;
}

.modalHeading {
  border-bottom: 2px solid #ba9173;
  height: 1.5rem;
}

.home-icons {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.icoDiv {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  // justify-content: space-between !important;
  align-items: flex-start !important;

  .ico {
    width: 31%;
    align-items: flex-start;
    img {
      width: 4rem;
      height: 4rem;
      object-fit: contain;
    }
    p {
      font-size: 0.9rem;
      text-align: left;
      line-height: 100%;
      text-wrap: balance;
      margin-top: 1rem;
      width: 70%;
    }

    &:hover {
      img {
        transform: scale(1.2);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .sm-mt-5 {
    margin-top: 2.8rem !important;
  }

  .icoDiv {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .sm-pt-0 {
    padding-top: 0 !important;
  }
}

.ofs-txt {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 800;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #c9a2726e !important;
}

.homeSwipers .swiper-button-next:after,
.homeSwipers .swiper-button-prev:after {
  content: unset;
}
.homeSwipers .swiper-wrapper {
  padding-bottom: 3rem;
}

.homeSwipers .swiper-button-next {
  left: unset;
  right: 0;
  top: unset;
  bottom: 0;
}
.homeSwipers .swiper-button-prev {
  left: unset;
  top: unset;
  right: 3rem;
  bottom: 0;
}

.line-through .rupee-symbol {
  color: #b8b8b8 !important;
}

.rupee-symbol {
  color: #272727;
  font-family: Arial, Helvetica, sans-serif;
}

.currentPrice {
  color: #272727 !important;
}

.swiper-pagination-bullet-active{
  background:#be956d !important;
}