$primary-color: #222222;
$secondary-color: #ADA174;
$gold-d8: #d8af49;
$white: #fff;
$green-18: #189c5c;
$black: #000000;
$black-19: #191919;
$text-black-1a: #1a202c;
$grey-67: #676b70;
$grey-ee: #EEEAE6;
$black-25: #25282b;
$white-f9: #f9f9fa;
$grey-ca: #cacccf;
$white-f0: #f0f2f5;
$yellow-C1: #c18f13;
$yellow-c9: #C9AEAE;
$lightGrey: #D8D8D8;
$grey-79: #797979;
$red-d6: #D6433B;
$red-e3: #E32F28;
$grey-92: #929292;
$grey-f5: #F5F5F5;
$black-07: #070707;
$grey-9b: #9B9B9B;
$grey-86: #8692A6;
$grey-e3: #E3E3E3;
$grey-f3: #F3EDDF;
$grey-65: #656565;
$grey-a8: #A8A8A8;
$grey-b8: #B8B8B8;
$grey-7e: #7E7E7E;
$grey-a2: #A2A2A2;
$grey-ed: #EDEDED;
$yellow-b4: #B48867;
$grey-a7: #A7A7A7;
$green-2a: #2AA952;
$grey-eb: #EBEBEB;
$grey-9e: #9E9E9E;
$grey-69: #696F79;
$green-a6: #A6A3A3;
$yellow-c0: #C0976D;
$grey-d9: #D9D9D9;
$red-dc:#DC1F26;
$white-f8:#F8F8F8;

.text-secondary {
  color: $secondary-color  !important;
}

$myColors: (
  "secondary-color":$secondary-color,
  "grey-67":$grey-67,
  "black-25":$black-25,
  "primary-color":$primary-color,
  "black":$black,
  "gold-d8":$gold-d8,
  "yellow-C1":$yellow-C1,
  "grey-ee":$grey-ee,
  "white":$white,
  "grey-f5":$grey-f5,
  "grey-86":$grey-86,
  "grey-79":$grey-79,
  "grey-f3":$grey-f3,
  "grey-a8":$grey-a8,
  "grey-7e":$grey-7e,
  "grey-a2":$grey-a2,
  "grey-ed":$grey-ed,
  "green-2a":$green-2a,
  "grey-9b":$grey-9b,
  "grey-65":$grey-65,
  "red-d6":$red-d6,
  "grey-d9":$grey-d9
);

@each $name,
$colors in $myColors {
  $index: index($myColors, $colors);

  .text-#{$name} {
    color: $colors  !important;
  }

  .bg-#{$name} {
    background-color: $colors  !important;
  }

  .text-#{$name} {
    color: $colors  !important;
  }

  .bg-h-#{$name} {
    background-color: $colors  !important;
  }

  .bc-#{$name} {
    border-color: $colors  !important;
  }
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.bg-gradient {
  background: linear-gradient(91.1deg, #CDA774 -8.1%, #AB7C62 124.87%);
}

.w-90 {
  width: 90% !important;
}

.line-through {
  text-decoration: line-through;
}

.bg-none {
  background: none !important;
}

.border-d2 {
  border-color: #D2D2D2 !important;
}