@import "../abstracts/_abstracts";

#category {
  h4 {
    font-size: 2rem;
    font-family: Perpetua;
  }

  .order-sm-0 {
    p {
      font-family: Proxima Nova;
    }
  }

  .order-1 {
    .col-sm-8 {
      p {
        &:last-child {
          font-size: 1rem;
        }
      }
    }
  }

  .list-group-horizontal {
    .list-group-item {
      background: inherit;
    }
  }

  #sTabs {
    .slick-arrow {
      display: none !important;
    }

    p {
      &:nth-child(2) {
        color: $grey-92;
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
      }
    }

    .nav.nav-tabs {
      .nav-item {
        border: inherit;
      }

      .nav-item.nav-link {
        color: $grey-9b;
        border: inherit;
        border-style: none;
        font-size: 1.125rem;
        // margin: 0 auto;
      }

      .nav-link {
        padding: 0.5rem !important;
      }

      a.active {
        font-weight: 600;
        color: $black-07 !important;
        background-color: inherit;
        border-bottom: 2px solid $secondary-color !important;
      }
    }

    .slick-slide {
      div {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.slide {
  height: 100% !important;
}

@media only screen and (max-width: 600px) {
  #category {
    .w-90 {
      width: 98% !important;
    }

    .order-sm-1 {
      img {
        height: 152px;
        width: 356px;
      }
    }

    .order-1 {
      h4 {
        font-size: 2rem;
      }

      .col-sm-8 {
        p {
          &:last-child {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  #category #sTabs .slick-arrow {
    display: block !important;
  }

  #sTabs {
    width: 95%;

    .nav.nav-tabs {
      .nav-item.nav-link {
        font-size: 0.875rem !important;
        margin: 0 auto;
      }

      a.active {
        color: $secondary-color !important;
      }
    }

    .sliderCard {
      height: 215px;

      p {
        &:first-child {
          font-size: 0.875rem;
        }

        &:last-child {
          font-size: 0.8125rem;
        }
      }

      .bg-grey-f5 img {
        height: 118px;
      }
    }
  }

  div.col-sm-6.order-3 {
    h4 {
      font-size: 1.625rem !important;
    }

    p {
      font-size: 0.875rem;
    }
  }
}
